import { RedirectModal } from 'components/Modals/RedirectModal'
import { StorageFlowIds, useStorageFlowRouteSelector } from 'hooks/useStorageFlowRouteSelector'
import React from 'react'

export const IsStorageUnitStorageCheck = () => {
  const data = useStorageFlowRouteSelector()
  const show = data?.flowId && data.flowId === StorageFlowIds.STORAGE_UNIT

  return (
    <RedirectModal
      show={Boolean(show)}
      titleLokalizeKey="schedule.payment.hadActiveSubscription"
      descriptionLokalizeKey="already.subscribed.for.storage.unit"
      actionRoute={data.flowRoute}
      actionButtonLokalizeKey="flow.select.storage.unit.title"
    />
  )
}

import {
  type FeatureFlagResponseDto,
  type SupportedCities,
  SupportedCountries,
} from '@wanda-space/types'

import { request } from '../api-client'

const isLocalhost =
  typeof window !== 'undefined' ? window.location.hostname.includes('local') : true

export type FeatureFlagKey =
  | 'ENABLE_BID_LISTING'
  | 'ENABLE_CONNECT_ACCOUNT'
  | 'ENABLE_DB_PACKAGING_PRODUCTS'
  | 'ENABLE_DESCRIPTION_GENERATOR'
  | 'ENABLE_FLEX_ADDRESS'
  | 'ENABLE_FLOW_INTRODUCTION_STEP'
  | 'ENABLE_FREE_PICKUP_LISTING'
  | 'ENABLE_FREE_PICKUP_SERVICE'
  | 'ENABLE_LISTING_ADDONS'
  | 'ENABLE_PACKING_OPTION'
  | 'ENABLE_PAYMENT_ELEMENTS'
  | 'ENABLE_PAYMENT_INTENT_CONFIRMATION_API'
  | 'ENABLE_PRICE_GROUP_ORDERLINES'
  | 'ENABLE_SPACESHIP_FLOW_BUY'
  | 'ENABLE_SPACESHIP_FLOW_PACKAGING'
  | 'ENABLE_SPACESHIP_FLOW_PACKAGING_IN_MENUS'
  | 'ENABLE_SPACESHIP_FLOW_RETURN'
  | 'ENABLE_SPACESHIP_FLOW_SELL'
  | 'ENABLE_SPACESHIP_FLOW_SERVICES'
  | 'ENABLE_SPACESHIP_FLOW_STORAGE'
  | 'ENABLE_UNLIMITED_ORDERS'
  | 'ENABLE_SPACESHIP_FLOW_SERVICE_FROM_STORAGE'
  | 'ENABLE_USERS_MERGE'
  | 'ENABLE_ITEM_MIX_STEP'
  | 'ENABLE_ACCOUNTS_SWITCHING'
  | 'ENABLE_USERS_API_V2'
  | 'ENABLE_SHARING_UI'
  | 'ENABLE_SPACESHIP_WAREHOUSE_VISIT'
  | 'ENABLE_PRICE_INCREASE_PAGE'
  | 'ENABLE_REFERRAL'
  | 'ENABLE_SPACESHIP_FLOW_STORAGE_PER_ITEM'
  | 'ENABLE_SPACESHIP_FLOW_STORAGE_UNIT'
  | 'ENABLE_SPACESHIP_FLOW_STORAGE_UNIT_WITH_PICKUP'

export const defaults: Record<FeatureFlagKey, boolean> = {
  ENABLE_BID_LISTING: isLocalhost,
  ENABLE_CONNECT_ACCOUNT: isLocalhost,
  ENABLE_DB_PACKAGING_PRODUCTS: isLocalhost,
  ENABLE_DESCRIPTION_GENERATOR: isLocalhost,
  ENABLE_FLEX_ADDRESS: isLocalhost,
  ENABLE_FLOW_INTRODUCTION_STEP: isLocalhost,
  ENABLE_FREE_PICKUP_LISTING: isLocalhost,
  ENABLE_FREE_PICKUP_SERVICE: isLocalhost,
  ENABLE_LISTING_ADDONS: isLocalhost,
  ENABLE_PACKING_OPTION: isLocalhost,
  ENABLE_PAYMENT_ELEMENTS: isLocalhost,
  ENABLE_PAYMENT_INTENT_CONFIRMATION_API: isLocalhost,
  ENABLE_PRICE_GROUP_ORDERLINES: isLocalhost,
  ENABLE_UNLIMITED_ORDERS: isLocalhost,
  ENABLE_USERS_MERGE: isLocalhost,
  ENABLE_ITEM_MIX_STEP: isLocalhost,
  ENABLE_ACCOUNTS_SWITCHING: isLocalhost,
  ENABLE_USERS_API_V2: false,
  ENABLE_SHARING_UI: isLocalhost,
  ENABLE_SPACESHIP_WAREHOUSE_VISIT: isLocalhost,
  ENABLE_PRICE_INCREASE_PAGE: isLocalhost,

  ENABLE_SPACESHIP_FLOW_BUY: true,
  ENABLE_SPACESHIP_FLOW_PACKAGING: true,
  ENABLE_SPACESHIP_FLOW_PACKAGING_IN_MENUS: false,
  ENABLE_SPACESHIP_FLOW_RETURN: true,
  ENABLE_SPACESHIP_FLOW_SELL: true,
  ENABLE_SPACESHIP_FLOW_SERVICES: true,
  ENABLE_SPACESHIP_FLOW_STORAGE: true,
  ENABLE_SPACESHIP_FLOW_SERVICE_FROM_STORAGE: isLocalhost,
  ENABLE_REFERRAL: isLocalhost,
  ENABLE_SPACESHIP_FLOW_STORAGE_PER_ITEM: isLocalhost,
  ENABLE_SPACESHIP_FLOW_STORAGE_UNIT: isLocalhost,
  ENABLE_SPACESHIP_FLOW_STORAGE_UNIT_WITH_PICKUP: isLocalhost,
}

export async function getFeatureFlags({
  city,
  countryCode,
}: {
  city?: SupportedCities
  countryCode?: SupportedCountries
}): Promise<Record<FeatureFlagKey, boolean>> {
  const params: Record<string, string> = {}
  if (city) params.city = city
  if (countryCode) params.countryCode = countryCode

  return request<FeatureFlagResponseDto>({
    method: 'GET',
    url: 'feature-flags',
    params,
  }).then(({ flags }) => ({
    ...defaults,
    ENABLE_FLEX_ADDRESS: countryCode === SupportedCountries.NO ? isLocalhost : false,
    ENABLE_SPACESHIP_FLOW_PACKAGING_IN_MENUS:
      flags.ENABLE_SPACESHIP_FLOW_PACKAGING ?? defaults.ENABLE_SPACESHIP_FLOW_PACKAGING_IN_MENUS,
    ...flags,
  }))
}

import {
  format,
  formatDuration,
  getHours,
  getMinutes,
  getSeconds,
  intervalToDuration,
} from 'date-fns'
import type { TimeslotAvailabilityAdjusted } from 'hooks/useAvailableTimeslots'
import type { FormatMessage } from './format'

export function isTwoDaysAhead(deliveryDate: string, timeslot: { from: string; to: string }) {
  const MINIMUM_HOURS_AHEAD = 48
  const deliveryTimeFrom = new Date(deliveryDate)
  const startingHours = Math.ceil(Number(timeslot.from) / 100)
  deliveryTimeFrom.setHours(startingHours)
  deliveryTimeFrom.setMinutes(0)
  deliveryTimeFrom.setSeconds(0)

  const hoursBetween =
    (new Date(deliveryTimeFrom).getTime() - new Date(Date.now()).getTime()) / (60 * 60 * 1000)

  return hoursBetween >= MINIMUM_HOURS_AHEAD
}

export function isTimeSlotStillOpen(
  availability: Record<string, TimeslotAvailabilityAdjusted[]>,
  date?: Date,
  timeslot?: { from: string; to: string }
) {
  if (date && timeslot) {
    return availability[format(new Date(date), 'yyyy-MM-dd')]?.some(
      ({ from, to, isOpen }) => from === timeslot.from && to === timeslot.to && isOpen
    )
  }
  return false
}

export function getNextMonthAndYearForTimeSlots({ year, month }: { year: number; month: number }) {
  if (month === 12) {
    return {
      year: year + 1,
      month: 1,
    }
  }

  return {
    year,
    month: month + 1,
  }
}

export function getFormattedDuration(start: Date, end: Date, locate?: Locale) {
  const duration = intervalToDuration({ start, end })
  return formatDuration(duration, { format: ['hours', 'minutes'], locale: locate })
}

export function getTimestampInMilliseconds(timestampInSeconds: number) {
  return timestampInSeconds * 1000
}

export function isDate(date: unknown): date is Date {
  const utcDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/

  if (Number.isNaN(Date.parse(date as string))) {
    return false
  }

  if (!utcDateRegex.test(date as string)) {
    return false
  }

  return true
}

export function toDateString(date: string) {
  if (isDate(date)) {
    return date as Date
  } else {
    throw new Error('Invalid date')
  }
}

export function dateToString(date: Date) {
  return date as unknown as string
}

export function toOperatingHours(
  { openAt, closeAt }: { openAt: string; closeAt: string },
  formatMessage: FormatMessage
): string {
  const openAtDate = new Date(`1970-01-01T${openAt}`)
  const closeAtDate = new Date(`1970-01-01T${closeAt}`)

  const openTime = format(openAtDate, 'HH')
  const closeTime = format(closeAtDate, 'HH')

  if (getHours(closeAtDate) === 23 && getMinutes(closeAtDate) === 59) {
    if (
      getHours(openAtDate) === 0 &&
      getMinutes(openAtDate) === 0 &&
      getSeconds(openAtDate) === 0
    ) {
      return formatMessage({ id: 'word.anytime' })
    }

    return formatMessage({ id: 'phrase.open.hours' }, { openAt: openTime, closeAt: '24' })
  }

  return formatMessage({ id: 'phrase.open.hours' }, { openAt: openTime, closeAt: closeTime })
}

import { useProductSubscriptions } from './useProductSubscriptions'

export function useStorageUnitSubscriptions(options: { enabled: boolean } = { enabled: true }) {
  const productSubscriptions = useProductSubscriptions(options)

  if (productSubscriptions.data) {
    const storageUnitSubscriptions = productSubscriptions.data?.items.filter(
      (productSubscription) => productSubscription.userFeatureKey === 'STORAGE_UNIT'
    )

    return {
      ...storageUnitSubscriptions,
      data: storageUnitSubscriptions,
      hasStorageUnitSubscriptions: storageUnitSubscriptions.length > 0,
    }
  }

  return { ...productSubscriptions, hasStorageUnitSubscriptions: undefined }
}

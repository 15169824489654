import type {
  AccountId,
  Auth0Id,
  SupportedCities,
  SupportedCountries,
  WandaId,
} from '@wanda-space/types'
import type { Locale } from 'i18n'

export interface UserAddress {
  city: string
  countryCode?: SupportedCountries
  firstName: string
  lastName: string
  companyName: string | null
  phoneNumber: string
  street: string
  postalCode?: string
  floorNumber?: number
  addressComment?: string
  elevator?: boolean
  elevatorSize?: string
  orgNo?: string
  extraContactPerson?: string[]
}

export interface Profile {
  tutorial: boolean
  spaceName: string
}

export interface User {
  id: AccountId
  userId: Auth0Id
  email: string
  emailVerified: boolean
  locale: Locale
  address: UserAddress
  profile: Profile
  countryCode?: SupportedCountries
  city?: SupportedCities
  accountId?: AccountId
  firstName?: string
  lastName?: string
  companyName?: string
}

export interface UserV2 {
  id: WandaId
  auth0Id: Auth0Id
  defaultAccountId: AccountId
  emailVerified: boolean
  email: string
  firstName: string
  lastName: string
  createdAt: string
  updatedAt: string
  lastLogin: string
  locale: Locale
}

export interface RegisterUserRequestDto {
  locale: Locale
  postalCode?: string
  countryCode?: SupportedCountries
  email?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
}

export interface AccountResponseDto {
  id: AccountId

  email: string

  createdAt: Date
  updatedAt: Date

  connectAccountId?: string

  invoiceManually: boolean

  tutorial: boolean

  firstName?: string
  lastName?: string
  companyName?: string
  orgNumber?: string

  phoneNumber?: string
  locale: Locale
  space_name?: string
  vinden: boolean
  address?: UserAddress

  extraContactPerson?: string[]

  city?: SupportedCities
  countryCode?: SupportedCountries
}

export enum AccountType {
  COMPANY = 'COMPANY',
  PRIVATE = 'PRIVATE',
}

import { useQuery } from '@tanstack/react-query'
import { UserFeatures } from '@wanda-space/types'
import { getUsedSpace } from 'api-client/lib/routes/productSubscriptions'
import { useProductSubscriptions } from './useProductSubscriptions'

export function useM2Subscriptions(options: { enabled: boolean } = { enabled: true }) {
  const response = useProductSubscriptions(options)

  if (response.data) {
    const m2Subscriptions = response.data?.items.filter(
      (productSubscription) => productSubscription.userFeatureKey === UserFeatures.PAY_PER_AREA
    )

    return {
      ...response,
      data: m2Subscriptions,
      hasSquareMeterSubscriptions: m2Subscriptions.length > 0,
    }
  }

  return { ...response, hasSquareMeterSubscriptions: undefined }
}

export function useM2UsedSpace(options: { enabled: boolean; id?: string } = { enabled: false }) {
  return useQuery({
    queryFn: () => getUsedSpace(),
    queryKey: ['m2UsedSpace', options.id],
    enabled: options.enabled,
  })
}

import type {
  PaginatedResponseDto,
  PlaceOrderRequestDto,
  PlaceStorageUnitOrderBodyDto,
  StorageUnitOrderResponseDto,
} from '@wanda-space/types'
import { request } from '../api-client'

export async function createStorageUnitOrder(storageUnitOrder: PlaceStorageUnitOrderBodyDto) {
  return request({
    method: 'POST',
    url: 'storage-unit-orders',
    body: storageUnitOrder,
  })
}

export async function fetchStorageUnitOrder(id: string) {
  return request<StorageUnitOrderResponseDto>({
    method: 'GET',
    url: `storage-unit-orders/${id}`,
  })
}

export async function fetchStorageUnitOrders(page?: number, itemsPerPage?: number) {
  return request<PaginatedResponseDto<StorageUnitOrderResponseDto>>({
    method: 'GET',
    url: 'storage-unit-orders',
    params: { page, itemsPerPage },
  })
}

export async function createStorageUnitOrderWithPickup(payload: {
  storageUnitOrder: PlaceStorageUnitOrderBodyDto
  pickupOrder: PlaceOrderRequestDto
}) {
  return request({
    method: 'POST',
    url: 'storage-unit-orders/with-pickup',
    body: payload,
  })
}

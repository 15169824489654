import { Spinner } from '@wanda-space/noelle'
import { Routes, WANDA_LAST_ROUTE } from 'consts'
import { useAppSelector } from 'hooks/useAppSelector'
import { useAuth } from 'hooks/useAuth'
import type { Stepable } from 'interfaces'
import type { UserAddress } from 'interfaces/user'
import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { Login } from 'routes/Login/Login'
import {
  CollectUserInfo,
  type CollectUserInfoProps,
} from 'routes/Register/CollectUserInfo/CollectUserInfo'
import { RegisterForm } from 'routes/Register/RegisterForm'

export function shouldCollectUserDetails(userDetail?: UserAddress) {
  if (userDetail) {
    const { firstName, lastName, phoneNumber } = userDetail
    return !(firstName && lastName && phoneNumber)
  }

  return false
}

const RequireRegistration = ({
  nextPath,
  orderAddress,
  onAddressUpdate,
}: Stepable & CollectUserInfoProps) => {
  const { isAuthenticated, isAuthenticating } = useAuth()
  const [showLoginView, setShowLoginView] = useState<boolean>()
  const userAddress = useAppSelector((state) => state.user.user?.address)
  const isUserLoading = useAppSelector((state) => state.user.loading)

  if (isAuthenticating || isUserLoading) {
    return <Spinner />
  }

  if (isAuthenticated === false) {
    localStorage.setItem(WANDA_LAST_ROUTE, location.pathname)
    if (showLoginView) {
      return <Login hasHeroImage={false} registerButtonHandler={() => setShowLoginView(false)} />
    }
    return (
      <RegisterForm
        hasHeroImage={false}
        loginButtonHandler={() => setShowLoginView(true)}
        hideAccountTypeSelector
        userSignupDetails={
          orderAddress
            ? {
                postalCode: orderAddress.postalCode,
                email: orderAddress.email,
                firstName: orderAddress.firstName,
                lastName: orderAddress.lastName,
                phoneNumber: orderAddress.phoneNumber,
                countryCode: orderAddress.countryCode,
              }
            : undefined
        }
      />
    )
  }

  if (shouldCollectUserDetails(userAddress)) {
    return <CollectUserInfo orderAddress={orderAddress} onAddressUpdate={onAddressUpdate} />
  }
  if (nextPath) {
    return <Navigate to={nextPath} replace />
  }
  return <Navigate to={Routes.Space} />
}

export { RequireRegistration }

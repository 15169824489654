import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  type DiscountResponseDto,
  type ItemMixTypes,
  type StorageUnitOrderPlacedResponseDto,
  StorageUnitOrderType,
} from '@wanda-space/types'
import type { DateAndTime, OrderLineWithFullProductAndDiscount } from 'api-client'
import type { WarehouseWithAddress } from 'api-client/lib/routes/warehouses'
import type { ContactPerson } from 'components/contact-info/types'
import type { FlattenedDeliveryInfo } from 'interfaces'
import type { RootState } from 'reduxStore'
import { SliceNames } from 'reduxStore/constants'
import { discountMapper } from 'utils'
import type { StorageUnitFlowState } from './types'

export const initialState: StorageUnitFlowState = {
  type: StorageUnitOrderType.STORE,
  dateAndTime: {},
  address: {} as FlattenedDeliveryInfo,
  orderLines: {
    storageUnitProduct: null,
    timeslot: [],
  },
  warehouse: {} as WarehouseWithAddress,
}

export const StorageUnitFlowStateSlice = createSlice({
  name: SliceNames.STORAGE_UNIT,
  initialState,
  reducers: {
    setDateAndTime: (state, action: PayloadAction<DateAndTime>) => {
      state.dateAndTime = action.payload
    },
    setWarehouse: (state, action: PayloadAction<WarehouseWithAddress>) => {
      state.warehouse = action.payload
    },
    setStorageUnitProduct: (state, action: PayloadAction<OrderLineWithFullProductAndDiscount>) => {
      state.orderLines.storageUnitProduct = action.payload
    },
    resetAll: () => {
      return initialState
    },
    applyDiscounts: (
      state,
      action: PayloadAction<{ coupon?: string; discounts: DiscountResponseDto[] }>
    ) => {
      if (action.payload.coupon) {
        state.coupon = action.payload.coupon
      }

      state.orderLines.storageUnitProduct = state.orderLines.storageUnitProduct
        ? discountMapper([state.orderLines.storageUnitProduct], action.payload.discounts)[0]
        : null
    },
    setAddress: (state, action: PayloadAction<FlattenedDeliveryInfo>) => {
      state.address = action.payload
    },
    setContactPerson: (state, action: PayloadAction<ContactPerson>) => {
      state.contactPerson = action.payload
    },
    setStorageUnitOrderType: (state, action: PayloadAction<StorageUnitOrderType>) => {
      state.type = action.payload
    },
    setTimeslotOrderlines: (
      state,
      action: PayloadAction<OrderLineWithFullProductAndDiscount[]>
    ) => {
      state.orderLines.timeslot = action.payload
    },
    setItemMix: (state, action: PayloadAction<ItemMixTypes>) => {
      state.itemMix = action.payload
    },
  },
})

export const {
  setDateAndTime,
  setWarehouse,
  setStorageUnitProduct,
  resetAll,
  applyDiscounts,
  setAddress,
  setContactPerson,
  setStorageUnitOrderType,
  setTimeslotOrderlines,
  setItemMix,
} = StorageUnitFlowStateSlice.actions

export const itemMixSelector = (state: RootState) => state.storageUnitFlow.itemMix

export const storageUnitFlow = StorageUnitFlowStateSlice.reducer

import { postalCodeFormatter } from '@wanda-space/noelle'
import {
  type OpsType,
  OrderType,
  type PlaceOrderRequestDto,
  type PlaceStorageUnitOrderBodyDto,
  ProductPriceType,
  ProductTarget,
  StorageUnitOrderType,
  UserFeatures,
} from '@wanda-space/types'
import type {
  OrderLineWithFullProductAndDiscount,
  OrderlineInOrderResponse,
  Product,
} from 'api-client'
import type { User } from 'interfaces'
import { mapOrderLineToInputDto } from 'reduxStore/commonMappers'
import type { StorageUnitFlowState } from 'reduxStore/ducks/storageUnit/types'

type StorageUnitOrderWithPickupPayload = {
  storageUnitOrder: PlaceStorageUnitOrderBodyDto
  pickupOrder: PlaceOrderRequestDto
}

export const mapToStorageUnitOrderPayload = (
  storageUnitOrder: StorageUnitFlowState,
  user: User
): PlaceStorageUnitOrderBodyDto | StorageUnitOrderWithPickupPayload => {
  const { dateAndTime, type, address, warehouse, coupon, contactPerson } = storageUnitOrder
  const { storageUnitProduct } = storageUnitOrder.orderLines
  const orderLines = []

  if (storageUnitProduct) {
    orderLines.push(mapOrderLineToInputDto(storageUnitProduct))
  }

  if (!dateAndTime.date) {
    throw new Error('Missing date')
  }

  const orderDetails = {
    deliveryDate: dateAndTime.date,
    contactInfo: {
      companyName: address.companyName,
      email: address.email ? address.email : user.email,
      firstName: address.firstName,
      lastName: address.lastName,
      phoneNumber: address.phoneNumber,
      contactPerson: contactPerson,
    },
    coupon,
  }

  const storageUnitOrderPayload = {
    type,
    orderDetails: orderDetails,
    orderLines,
    warehouseInfo: {
      id: warehouse.id,
      name: warehouse.name,
      address: {
        street: warehouse.address.street,
        postalCode: warehouse.address.postalCode,
      },
    },
  }

  let pickupOrderPayload: PlaceOrderRequestDto | undefined = undefined

  if (type === StorageUnitOrderType.STORE_WITH_PICK_UP) {
    const { timeslot } = storageUnitOrder.orderLines
    const { itemMix } = storageUnitOrder

    if (!dateAndTime.timeslot) {
      throw new Error('Missing timeslot')
    }

    pickupOrderPayload = {
      type: OrderType.PICK_UP,
      orderDetails: {
        ...orderDetails,
        deliveryTimeSlot: {
          from: dateAndTime.timeslot.from,
          to: dateAndTime.timeslot.to,
        },
        extraDeliveryInfo: address.extraDeliveryInfo,
        floorNumber: address.floorNumber ?? 0,
        elevator: address.elevator,
        coupon,
        itemMix,
        contactInfo: {
          ...orderDetails.contactInfo,
          firstName: user.address.firstName,
          lastName: user.address.lastName,
          companyName: address.companyName,
          address: {
            street: address.street,
            city: address.city,
            postalCode: postalCodeFormatter(address.postalCode),
            countryCode: address.countryCode,
          },
          email: user.email,
          mobilePhone: user.address.phoneNumber,
        },
        locale: user.locale,
      },
      orderLines: orderLines.concat(timeslot.map(mapOrderLineToInputDto)),
      storageItemIds: [],
    }
  }

  if (type === StorageUnitOrderType.STORE_WITH_PICK_UP) {
    if (!pickupOrderPayload) {
      throw new Error('Missing pickup order payload')
    }

    return {
      storageUnitOrder: storageUnitOrderPayload,
      pickupOrder: pickupOrderPayload,
    }
  }

  return storageUnitOrderPayload
}

export const isProductStorageUnit = (
  product:
    | Product
    | OrderLineWithFullProductAndDiscount['product']
    | OrderlineInOrderResponse['product']
) => {
  return (
    product.metadata?.userFeatureKey === 'STORAGE_UNIT' &&
    product.priceType === ProductPriceType.RECURRING &&
    product.target === ProductTarget.USER &&
    product.active
  )
}

export const hasStorageUnitOrderline = (
  orderlines: (OrderLineWithFullProductAndDiscount | OrderlineInOrderResponse)[]
) => {
  return orderlines.find((o) => isProductStorageUnit(o.product))
}

export const isProductStorageUnitTaas = (product: Product, opsType?: OpsType) => {
  return (
    product.metadata.userFeatureKey === UserFeatures.STORAGE_UNIT &&
    product.priceType === ProductPriceType.ONE_TIME &&
    product.target === ProductTarget.ORDER &&
    product.active &&
    (!opsType || product.metadata.opsType === opsType)
  )
}

export const isStorageUnitOrderWithPickup = (
  payload: StorageUnitOrderWithPickupPayload | PlaceStorageUnitOrderBodyDto
): payload is StorageUnitOrderWithPickupPayload => {
  if ((payload as StorageUnitOrderWithPickupPayload)?.pickupOrder) {
    return true
  }
  return false
}

import { Box } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { Alert, Notification, Spinner } from '@wanda-space/noelle'
import {
  OrderState,
  OrderType,
  type PlacedOrderResponseDto,
  StorageItemState,
} from '@wanda-space/types'
import { createOrderWithItems } from 'api-client/lib/routes/orders'
import { Routes, WANDA_LAST_ROUTE } from 'consts'
import { useDateLocale } from 'contexts/Intl'
import { format } from 'date-fns'
import { getEnv } from 'env'
import { useAppSelector } from 'hooks/useAppSelector'
import { Connection, useAuth } from 'hooks/useAuth'
import { useCheckTimeslotOpen } from 'hooks/useCheckTimeslotOpen'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { useOrders, useOrdersInvalidator } from 'hooks/useOrders'
import { useProductsAndCategories } from 'hooks/useProductsAndCategories'
import { useTracker } from 'hooks/useTracker'
import type { Stepable } from 'interfaces'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useAppDispatch } from 'reduxStore'
import { mapItemToPayload, mapStorageToOrderDto } from 'reduxStore/commonMappers'
import {
  applyDiscounts,
  itemPayloadSelectors,
  resetAll,
  setOrderSuccessPayload,
} from 'reduxStore/ducks/storage/storageFlow'
import {
  type OneTimePaymentItem,
  type PaymentItem,
  PaymentStepBase,
} from 'routes/Common/PaymentStepBase'
import {
  add,
  getDiscountFromPrice,
  getPriceForAddons,
  getProductLocalizedName,
  getStoragePrices,
  isOrderlineForOnetimeProduct,
  isOrderlineForRecurringProduct,
  pathTo,
  productToDiscount,
  productToPrice,
} from 'utils'
import { serializeError } from 'utils/errors'
import { getCarryingMethodLabel } from 'utils/getCarryingMethodLabel'
import { orderlineToPrice, orderlineToProduct } from 'utils/orderline'

import { useItems } from 'hooks/useItems'
import {
  handleTagManagerCheckout /*, handleTagManagerPurchase*/,
} from './OldComponents/Summary/helpers'
import { paths } from './paths'

export const PaymentAndSummary = ({ onNextStep }: Stepable) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { data: orders } = useOrders()
  const [error, setError] = useState('')
  const [isSubmittingOrder, setIsSubmittingOrder] = useState(false)
  const invalidateOrderCache = useOrdersInvalidator()
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user.user)
  const storageFlowState = useAppSelector((state) => state.storageFlow)
  const [searchParams] = useSearchParams()
  const passwordless = searchParams.has('passwordless')
  const handlePasswordlessLogin = () => {
    localStorage.setItem(WANDA_LAST_ROUTE, location.pathname)
    universalLogin(Connection.SMS, {
      email: storageFlowState.address.email,
      postalCode: storageFlowState.address.postalCode,
      countryCode: storageFlowState.address.countryCode,
      firstName: storageFlowState.address.firstName,
      lastName: storageFlowState.address.lastName,
    })
  }
  const { data: featureFlags } = useFeatureFlags()
  const dateLocale = useDateLocale()
  const { isAuthenticated, universalLogin } = useAuth()
  const orderSuccessPayload = useAppSelector((state) => state.storageFlow.orderSuccessPayload)

  const { data: allExistingItemsOnUser } = useItems(user, { enabled: isAuthenticated })

  const activeOrders = orders?.filter(({ state }) =>
    [OrderState.ORDER_SUBMITTED, OrderState.PAYMENT_AUTHORISED].includes(state)
  )

  const hasActiveOrderLimitReached =
    !featureFlags?.ENABLE_UNLIMITED_ORDERS && activeOrders && activeOrders.length >= 3

  const orderType = useAppSelector((state) => state.storageFlow.orderType)
  const addonsOrderlines = useAppSelector((state) => state.storageFlow.orderLines.addons)
  const newItems = useAppSelector(itemPayloadSelectors.selectAll)
  const existingItems = useAppSelector((state) => state.storageFlow.existingItems)
  const storageProducts = useAppSelector((state) => state.storageFlow.orderLines.storage).map(
    ({ product }) => product
  )

  const tracker = useTracker()
  const locale = useAppSelector((state) => state.user.user?.locale || state.ui.language)
  const alreadyStoredItems =
    allExistingItemsOnUser?.filter(({ state }) => state === StorageItemState.STORED) || []
  const items = [...newItems, ...existingItems, ...alreadyStoredItems]
  const selectedItems = [...newItems, ...existingItems]
  const scheduleDate = storageFlowState.dateAndTime.date
    ? new Date(storageFlowState.dateAndTime.date)
    : undefined

  const { products, isLoading: productsLoading } = useProductsAndCategories({
    couponCode: storageFlowState.coupon,
  })

  /* Start: One time costs */
  const oneTimeAddonsOrderlines = addonsOrderlines.filter(isOrderlineForOnetimeProduct)
  const taasPrice = add(...storageFlowState.orderLines.taas.map(orderlineToPrice))
  const oneTimeAddonPrice = add(...oneTimeAddonsOrderlines.map(orderlineToPrice))
  const packingPrice = add(...storageFlowState.orderLines.packing.map(orderlineToPrice))
  const timeslotPrice = add(...storageFlowState.orderLines.timeslot.map(orderlineToPrice))
  /* End: One time costs */

  /* Start: Recurring cost */
  const recurringAddonsOrderlines = addonsOrderlines.filter(isOrderlineForRecurringProduct)
  const prices = products ? getStoragePrices(products) : undefined
  const recurringAddOnsPrice = getPriceForAddons(recurringAddonsOrderlines)
  const recurringStoragePrice = add(...storageFlowState.orderLines.storage.map(orderlineToPrice))
  /* End: Recurring cost */

  /* Start: Total costs */
  const totalOneTimeCost = add(oneTimeAddonPrice, taasPrice, packingPrice ?? 0, timeslotPrice ?? 0)
  const totalSubscriptionCost = add(recurringStoragePrice, recurringAddOnsPrice)
  /* End: Total costs */

  const [isTimeslotOpen] = useCheckTimeslotOpen({
    orderType: orderType,
    dateAndTime: storageFlowState.dateAndTime,
    items: selectedItems,
  })

  const next = (orderSuccessPayload: PlacedOrderResponseDto) => {
    const { orderId } = orderSuccessPayload

    if (prices) {
      handleTagManagerCheckout(items, user, prices)
      // Temporarily disabled, to avoid duplicate events, see https://linear.app/wanda/issue/WAN-3013
      // handleTagManagerPurchase(orderId, items, prices, user, true)
      tracker.placeOrder(totalOneTimeCost)
    }

    dispatch(resetAll())
    navigate(
      pathTo(Routes.Storage, `${paths.bookingConfirmation}?orderStatus=success&orderId=${orderId}`)
    )
    localStorage.removeItem('obosNo')
  }

  const createOrder = async () => {
    setError('')
    if (!user) {
      throw new Error('Unable to fetch user before placing order')
    }
    if (orderSuccessPayload) {
      next(orderSuccessPayload)
    }

    setIsSubmittingOrder(true)
    try {
      const response = await createOrderWithItems(
        mapStorageToOrderDto(orderType, storageFlowState, newItems, locale, user!),
        newItems.map(mapItemToPayload)
      )
      dispatch(setOrderSuccessPayload(response))
      invalidateOrderCache()
      next(response)
    } catch (error: unknown) {
      setError(formatMessage({ id: serializeError(error).localizationKey }))
      captureException(error)
    } finally {
      setIsSubmittingOrder(false)
    }
  }

  const totalTaasPaymentItem: OneTimePaymentItem = {
    name: formatMessage({
      id: getCarryingMethodLabel(orderType, storageFlowState.serviceLevel),
    }),
    isTaas: true,
    oldPrice: taasPrice.oldPrice,
    price: taasPrice.amount,
    discount: getDiscountFromPrice(taasPrice)?.amount,
  }

  const oneTimePaymentItems: OneTimePaymentItem[] = [
    totalTaasPaymentItem,
    {
      name: formatMessage({
        id: 'booking.packing.orderline.description',
      }),
      oldPrice: packingPrice.oldPrice,
      price: packingPrice.amount,
      discount: getDiscountFromPrice(packingPrice)?.amount,
    },
    {
      name: formatMessage({
        id: 'booking.summary.timeslot',
      }),
      oldPrice: timeslotPrice.oldPrice,
      price: timeslotPrice.amount,
      discount: getDiscountFromPrice(timeslotPrice)?.amount,
    },
    ...oneTimeAddonsOrderlines.map(({ product }) => {
      const productPrice = productToPrice(product)
      return {
        name: getProductLocalizedName(product, formatMessage),
        oldPrice: productPrice.oldPrice,
        price: productPrice.amount,
        discount: getDiscountFromPrice(productPrice)?.amount,
      }
    }),
  ]

  const monthlyPaymentItems: PaymentItem[] = [
    ...storageFlowState.orderLines.storage.map(orderlineToProduct).map((product) => {
      const productPrice = productToPrice(product)
      const discount = productToDiscount(product)
      return {
        name: getProductLocalizedName(product, formatMessage),
        price: productPrice.amount,
        discount: discount?.amount,
      }
    }),
    ...recurringAddonsOrderlines.map(orderlineToProduct).map((product) => {
      const productPrice = productToPrice(product)
      const discount = productToDiscount(product)
      return {
        name: getProductLocalizedName(product, formatMessage),
        price: productPrice.amount,
        discount: discount?.amount,
      }
    }),
  ]

  return (
    <>
      {error && (
        <Notification
          id="error"
          dismissible={false}
          text={formatMessage({ id: error })}
          type="errors"
          wide
        />
      )}
      {hasActiveOrderLimitReached && (
        <Notification
          id="error"
          dismissible={false}
          title={formatMessage({ id: 'error.order.tooMany.title' })}
          text={formatMessage({ id: 'error.order.tooMany.description' })}
          type="errors"
          wide
        />
      )}
      {!isTimeslotOpen && storageFlowState?.dateAndTime?.date && (
        <Box mb={5} mt={3} data-testid="warning-timeslot-taken">
          <Alert
            duration={0}
            id="alert-warning-id"
            status="error"
            button={{
              children: formatMessage({ id: 'alert.order.state.timeslotTaken.editDate' }),
              onClick: () => {
                navigate(Routes.StorageSchedule, {
                  state: { referrer: location.pathname },
                })
              },
            }}
            text={formatMessage(
              { id: 'alert.order.state.timeslotTaken.text' },
              {
                date: (
                  <b>
                    {format(new Date(storageFlowState.dateAndTime.date), 'EEEE dd MMMM', {
                      locale: dateLocale,
                    })}
                  </b>
                ),
              }
            )}
          />
        </Box>
      )}

      {productsLoading ? (
        <Spinner />
      ) : (
        <PaymentStepBase
          error={error}
          hideYouPayNow
          onObosMembershipActivation={(discounts) => dispatch(applyDiscounts({ discounts }))}
          country={storageFlowState.address.countryCode}
          addCoupon={(discounts, coupon) => dispatch(applyDiscounts({ discounts, coupon }))}
          coupon={storageFlowState.coupon}
          discountDuration={
            storageProducts.find(({ discount }) => discount?.duration)?.discount?.duration
          }
          onNextStep={
            getEnv('APP_ENVIRONMENT') === 'prd'
              ? handlePasswordlessLogin
              : passwordless
                ? handlePasswordlessLogin
                : onNextStep
          }
          totalSubscriptionCost={totalSubscriptionCost.amount}
          amountOfStoredItems={items.length}
          order={{
            address: `${storageFlowState.address.street}, ${storageFlowState.address.city}`,
            ownerName: storageFlowState.contactPerson?.name.length
              ? storageFlowState.contactPerson?.name
              : `${storageFlowState.address.firstName} ${storageFlowState.address.lastName}`,
            date: scheduleDate,
            timeslot: storageFlowState.dateAndTime.timeslot,
          }}
          disableOfferInvoiceOption={true}
          createOrder={createOrder}
          isSubmittingOrder={isSubmittingOrder}
          hasActiveOrderLimitReached={hasActiveOrderLimitReached || false}
          isTimeslotOpen={isTimeslotOpen}
          changeAddress={Routes.StorageAddress}
          changeDate={Routes.StorageSchedule}
          totalTransactionPrice={totalOneTimeCost.amount + totalSubscriptionCost.amount}
          oneTimePayment={{
            defaultCollapsed: false,
            price: totalOneTimeCost.amount,
            oneTimeServices: oneTimePaymentItems,
            oldPrice: totalOneTimeCost.oldPrice,
            items: oneTimePaymentItems,
          }}
          monthlyPayment={{
            price: totalSubscriptionCost.amount,
            amountOfThings: storageProducts.length,
            oldPrice: totalSubscriptionCost.oldPrice,
            items: monthlyPaymentItems,
          }}
        />
      )}
    </>
  )
}

import type { PlaceOrderRequestDto } from '@wanda-space/types'
import type { FlattenedDeliveryInfo } from 'interfaces'

export type ContactPerson = NonNullable<
  PlaceOrderRequestDto['orderDetails']['contactInfo']['contactPerson']
>

export enum FieldNames {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE_NUMBER = 'phoneNumber',
  STREET = 'street',
  POSTCODE = 'postalCode',
  CITY = 'city',
  ADDRESS_COMMENT = 'addressComment',
  FLOOR_NUMBER = 'floorNumber',
  ELEVATOR = 'elevator',
  COMPANY_NAME = 'companyName',
  EMAIL = 'email',
  COUNTRY_CODE = 'countryCode',
}
export type ContactInfoData = Omit<
  FlattenedDeliveryInfo,
  'extraDeliveryInfo' | 'contactLess' | 'shortStorage'
> & {
  [FieldNames.ADDRESS_COMMENT]: string
}

export interface ContactInfoPageSubmitHandlerParams {
  contactPerson: ContactPerson
  contactInfo: ContactInfoData
}

export enum AddressRenderState {
  MOUNTING = 'mounting',
  ORDER_ADDRESS_ACTIVE = 'order-address-active',
  USER_DEFAULT_ADDRESS_INCOMPLETE = 'user-default-address-incomplete',
  CHANGE_ORDER_ADDRESS = 'change-order-address',
  USER_DEFAULT_ADDRESS = 'user-default-address',
}

export type UpdateContactPerson = (contactPerson: ContactPerson) => void
export type ContactInfoPageSubmitHandler = (values: ContactInfoPageSubmitHandlerParams) => void
export type UpdateAddressRenderState = (state: AddressRenderState) => void

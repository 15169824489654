import type { RootState } from 'reduxStore'

import type { ItemResponseDto } from '@wanda-space/types'
import type { ItemPayloadWithProduct } from 'reduxStore/commonMappers'
import { itemPayloadSelectors } from './storageFlow'

export const combinedItemsSelector = (
  state: RootState
): (ItemResponseDto | ItemPayloadWithProduct)[] => [
  ...state.storageFlow.existingItems,
  ...itemPayloadSelectors.selectAll(state),
]

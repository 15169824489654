import { OrderType } from '@wanda-space/types'
import { ContactInfoCard } from 'components/contact-info/ContactInfoCard'
import { ContactInfoForm } from 'components/contact-info/ContactInfoForm'
import { useAddressRenderStates, useContactInfoData } from 'components/contact-info/hooks'
import {
  AddressRenderState,
  type ContactInfoData,
  type ContactInfoPageSubmitHandlerParams,
  type ContactPerson,
} from 'components/contact-info/types'
import { showContactCardCheck } from 'components/contact-info/utils'
import { useActiveUserLocation } from 'hooks/useActiveUserLocation'
import { useAppSelector } from 'hooks/useAppSelector'
import { useAuth } from 'hooks/useAuth'
import { useHideFields } from 'hooks/useHideFields'
import { useWarehouseForVisit } from 'hooks/useWarehouseForVisit'
import type { FlattenedDeliveryInfo, Stepable } from 'interfaces'
import React from 'react'
import type { ActionCreator, AnyAction } from 'redux'
import { useAppDispatch } from 'reduxStore'
import {
  mapContactInfoDataToDeliveryInfo,
  mapDeliveryInfoToContactInfoData,
} from 'reduxStore/ducks/user/mappers'
import { fetchUser, patchAddress } from 'reduxStore/ducks/user/user'

interface PickupAddressContactInfoProps extends Stepable {
  orderType: OrderType
  orderAddress: FlattenedDeliveryInfo
  orderContactPerson: ContactPerson | undefined
  setAddress: ActionCreator<AnyAction>
  setContactPerson: ActionCreator<AnyAction>
  changeAddressButtonTextId?: string
}

export const PickupAddressContactInfo = ({
  orderType,
  onNextStep,
  orderContactPerson,
  orderAddress,
  setAddress,
  setContactPerson,
  changeAddressButtonTextId,
}: PickupAddressContactInfoProps) => {
  const { isAuthenticated } = useAuth()
  const dispatch = useAppDispatch()
  const { addressRenderState, setAddressRenderState, setInitialRenderState } =
    useAddressRenderStates()
  const { country, serviceArea } = useActiveUserLocation()
  const mappedOrderAddress = mapDeliveryInfoToContactInfoData(orderAddress, country)
  const { contactInfo, userAddress, isUserAddressComplete } = useContactInfoData(mappedOrderAddress)
  const hideFields = useHideFields(orderType === OrderType.CUSTOMER_DROP_OFF)
  const warehouse = useWarehouseForVisit({ serviceArea })

  if (userAddress) {
    setInitialRenderState(userAddress, mappedOrderAddress)
  }

  const onSubmit = async (values: ContactInfoPageSubmitHandlerParams) => {
    const { contactInfo, contactPerson } = values
    const deliveryContactInfo = mapContactInfoDataToDeliveryInfo(
      orderType,
      contactInfo,
      warehouse.data?.address
    )

    dispatch(setAddress(deliveryContactInfo))
    dispatch(setContactPerson(contactPerson))

    if (userAddress && isUserAddressComplete === false) {
      await dispatch(patchAddress(contactInfo)).unwrap()
      await dispatch(fetchUser()).unwrap()
    }

    onNextStep()
  }

  const setDefaultUserAddress = (state: AddressRenderState) => {
    if (state === AddressRenderState.USER_DEFAULT_ADDRESS && userAddress) {
      dispatch(
        setAddress(
          mapContactInfoDataToDeliveryInfo(
            orderType,
            userAddress as unknown as ContactInfoData,
            warehouse.data?.address
          )
        )
      )
    }

    setAddressRenderState(state)
  }

  const showContactInfoCard =
    showContactCardCheck(addressRenderState) && isAuthenticated && orderType === OrderType.PICK_UP
  const showUpdateAddressRenderState = isUserAddressComplete && orderType === OrderType.PICK_UP

  return showContactInfoCard ? (
    <ContactInfoCard
      showContactPerson
      onSubmit={onSubmit}
      defaultContactPerson={orderContactPerson}
      contactInfo={contactInfo}
      updateAddressRenderState={setAddressRenderState}
      changeAddressButtonTextId={
        changeAddressButtonTextId ? changeAddressButtonTextId : 'word.change.pickup.address'
      }
    />
  ) : (
    <ContactInfoForm
      hideFields={hideFields}
      showContactPerson
      contactPerson={orderContactPerson}
      contactInfo={contactInfo}
      onSubmit={onSubmit}
      updateAddressRenderState={showUpdateAddressRenderState ? setDefaultUserAddress : undefined}
    />
  )
}

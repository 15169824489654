import type { BulletPoint } from '@wanda-space/noelle'
import { StorageUnitOrderType } from '@wanda-space/types'
import type { FeatureFlagKey } from 'api-client/lib/routes/feature-flags'
import { format } from 'date-fns'
import type { StorageUnitFlowState } from 'reduxStore/ducks/storageUnit/types'
import { type FormatMessage, formatAddress } from 'utils'

const selectUnitSize = 'select-unit-size'
const selectWarehouse = 'select-warehouse'
const schedule = 'schedule'
const address = 'address'
const register = 'register'
const summary = 'summary'
const payment = 'payment'
const confirmation = 'confirmation'
const disclaimer = 'disclaimer'
const selectTransport = 'select-transport'
const itemMix = 'item-mix'

export const paths = {
  selectUnitSize,
  selectWarehouse,
  selectTransport,
  schedule,
  summary,
  address,
  register,
  payment,
  disclaimer,
  confirmation,
  itemMix,
}

export const bulletPoints = (
  formatMessage: FormatMessage,
  data: StorageUnitFlowState,
  locale: Locale | undefined,
  featureFlags?: Record<FeatureFlagKey, boolean>
): BulletPoint[] => {
  return [
    {
      summaryText: data.warehouse.name,
      inProgressText: formatMessage({ id: 'nav.warehouse.default' }),
      link: paths.selectWarehouse,
    },
    {
      summaryText: data.orderLines.storageUnitProduct
        ? `${formatMessage({
            id: `${data.orderLines.storageUnitProduct.product.localizationKey}.name`,
          })} ${formatMessage({ id: 'word.storing' })}`
        : undefined,
      inProgressText: formatMessage({ id: 'nav.storing.default' }),
      link: paths.selectUnitSize,
    },
    ...(featureFlags?.ENABLE_SPACESHIP_FLOW_STORAGE_UNIT_WITH_PICKUP
      ? [
          {
            summaryText:
              data.type === StorageUnitOrderType.STORE_WITH_PICK_UP
                ? formatMessage({ id: 'word.pickup' })
                : formatMessage({ id: 'word.selfTransport' }),
            inProgressText: formatMessage({ id: 'nav.transport.default' }),
            link: paths.selectTransport,
          },
        ]
      : []),
    {
      summaryText: data.dateAndTime.date
        ? formatMessage(
            { id: 'editOrderDetails.pickupTime' },
            {
              date: `${format(new Date(data.dateAndTime.date), 'EEEE do MMM', {
                locale,
              })}`,
            }
          )
        : undefined,
      inProgressText: formatMessage({ id: 'nav.timeAndDate.default' }),
      link: paths.schedule,
    },
    {
      summaryText: data.address.street ? formatAddress(data.address) : undefined,
      inProgressText: formatMessage({ id: 'word.address' }),
      link: paths.address,
    },
    {
      inProgressText: formatMessage({ id: 'word.payment' }),
      link: paths.payment,
    },
  ]
}

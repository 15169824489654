import { useQuery } from '@tanstack/react-query'
import { fetchStorageUnitOrders } from 'api-client/lib/routes/storageUnitOrder'
import { ReactQueryKey } from 'consts'
import { useAppSelector } from './useAppSelector'
import { useAuth } from './useAuth'

export const useStorageUnitOrders = (page = 0, itemsPerPage = 1000) => {
  const { isAuthenticated } = useAuth()
  const accountId = useAppSelector((state) => state.user.user?.id)

  return useQuery(
    [ReactQueryKey.FETCH_STORAGE_UNIT_ORDERS, accountId],
    () => fetchStorageUnitOrders(page, itemsPerPage),
    {
      enabled: !!accountId && isAuthenticated,
      refetchOnMount: false,
    }
  )
}

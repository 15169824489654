import { useQuery } from '@tanstack/react-query'
import { getProductSubscriptions } from 'api-client/lib/routes/productSubscriptions'
import { useAuth } from './useAuth'

export function useProductSubscriptions(options: { enabled: boolean } = { enabled: true }) {
  const { isAuthenticated } = useAuth()

  return useQuery({
    queryFn: () => getProductSubscriptions(),
    queryKey: ['productSubscriptions'],
    enabled: isAuthenticated && options.enabled,
  })
}

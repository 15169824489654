import { Notification } from '@wanda-space/noelle'
import { OrderState, type ServiceOrderResponseDto, StorageItemState } from '@wanda-space/types'
import { getOrders } from 'api-client/lib/routes/orders'
import { createServiceFromStorage } from 'api-client/lib/routes/serviceOrders'
import { Routes } from 'consts'
import { useAppSelector } from 'hooks/useAppSelector'
import { useAuth } from 'hooks/useAuth'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import type { Stepable } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from 'reduxStore'
import {
  selectCoupon,
  selectOrderSuccessPayload,
  selectServiceOrderLines,
} from 'reduxStore/ducks/serviceFromStorage/selectors'
import {
  applyDiscounts,
  resetAll,
  setOrderSuccessPayload,
} from 'reduxStore/ducks/serviceFromStorage/serviceFromStorage'
import { type OneTimePaymentItem, PaymentStepBase } from 'routes/Common/PaymentStepBase'
import { add, getDiscountFromPrice, getProductLocalizedName, productToPrice } from 'utils'
import { orderlineToPrice } from 'utils/orderline'

import { captureException } from '@sentry/react'
import { useItems } from 'hooks/useItems'
import { createServiceOrderPayload } from './helpers'

export const PaymentAndSummary = ({ onNextStep }: Stepable) => {
  const [error, setError] = useState('')
  const { formatMessage } = useIntl()
  const [orderCreateLoading, setOrderCreateLoading] = useState<boolean>(false)
  const [hasActiveOrderLimitReached, setHasActiveOrderLimitReached] = useState<boolean>(false)
  const coupon = useAppSelector(selectCoupon)
  const user = useAppSelector((state) => state.user.user)
  const ui = useAppSelector((state) => state.ui)
  const { data: featureFlags, isInitialLoading: isFeatureFlagLoading } = useFeatureFlags()
  const serviceOrderLines = useAppSelector(selectServiceOrderLines)
  const orderSuccessPayload = useAppSelector(selectOrderSuccessPayload)
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuth()

  const serviceItemsList = serviceOrderLines.map(({ product }) => {
    return { name: product.name, price: product.price }
  })

  const { data: allExistingItemsOnUser } = useItems(user, { enabled: isAuthenticated })

  const alreadyStoredItems =
    allExistingItemsOnUser?.filter(({ state }) => state === StorageItemState.STORED) || []

  const next = (order: ServiceOrderResponseDto) => {
    dispatch(resetAll())
    onNextStep({ queryParams: { orderIds: order.id } })
  }

  useEffect(() => {
    if (isFeatureFlagLoading) {
      return
    } else if (featureFlags?.ENABLE_UNLIMITED_ORDERS) {
      setHasActiveOrderLimitReached(false)
    } else if (user?.id) {
      getOrders(0, 1000).then((orders) => {
        const activeOrders = orders.filter(({ state }) =>
          [OrderState.ORDER_SUBMITTED, OrderState.PAYMENT_AUTHORISED].includes(state)
        )
        const orderLimitReached = activeOrders.length >= 3
        setHasActiveOrderLimitReached(orderLimitReached)
      })
    }
  }, [user?.id, featureFlags])

  const createOrder = async () => {
    if (orderSuccessPayload) {
      next(orderSuccessPayload)
    }

    if (serviceOrderLines && user) {
      setOrderCreateLoading(true)

      const payload = createServiceOrderPayload({
        serviceOrderLines,
        user,
        coupon,
      })

      try {
        const response = await createServiceFromStorage(payload)
        dispatch(setOrderSuccessPayload(response))
        next(response)
      } catch (error: any) {
        setError(error)
        captureException(error)
      } finally {
        setOrderCreateLoading(false)
      }
    }
  }

  const totalOneTimeCost = add(...serviceOrderLines.map(orderlineToPrice))

  const oneTimePaymentItems: OneTimePaymentItem[] = [
    ...serviceOrderLines.map(({ product }) => {
      const productPrice = productToPrice(product)
      return {
        name: getProductLocalizedName(product, formatMessage),
        isTaas: false,
        product,
        oldPrice: productPrice.oldPrice,
        price: productPrice.amount,
        discount: getDiscountFromPrice(productPrice)?.amount,
      }
    }),
  ]

  const oneTimeItems: { name: string }[] = [
    {
      name: formatMessage(
        {
          id: 'nav.service',
        },
        { number: serviceItemsList.length }
      ),
    },
  ]

  return (
    <>
      {error && (
        <Notification
          id="error"
          dismissible={false}
          text={formatMessage({ id: error })}
          type="errors"
          wide
        />
      )}
      {hasActiveOrderLimitReached && (
        <Notification
          id="error"
          dismissible={false}
          title={formatMessage({ id: 'error.order.tooMany.title' })}
          text={formatMessage({ id: 'error.order.tooMany.description' })}
          type="errors"
          wide
        />
      )}

      <PaymentStepBase
        error={error}
        onObosMembershipActivation={(discounts) => dispatch(applyDiscounts({ discounts }))}
        country={ui.country}
        addCoupon={(discounts, coupon) => applyDiscounts({ discounts, coupon })}
        coupon={coupon}
        onNextStep={onNextStep}
        amountOfStoredItems={serviceItemsList.length + alreadyStoredItems.length}
        disableOfferInvoiceOption={true}
        createOrder={createOrder}
        isSubmittingOrder={orderCreateLoading}
        hasActiveOrderLimitReached={hasActiveOrderLimitReached ?? false}
        isTimeslotOpen
        changeAddress={`${Routes.ServicesNext}/pickup-address`}
        changeDate={`${Routes.ServicesNext}/select-date`}
        totalTransactionPrice={totalOneTimeCost.amount}
        oneTimePayment={{
          isService: true,
          defaultCollapsed: false,
          oneTimeServices: oneTimeItems,
          oldPrice: totalOneTimeCost.oldPrice,
          price: totalOneTimeCost.amount,
          items: oneTimePaymentItems,
        }}
      />
    </>
  )
}

/** Redux Toolkit slice names, for consistent reuse */
export enum SliceNames {
  USER = 'user',
  SERVICE = 'services next flow',
  STORAGE = 'storage flow',
  RETURN = 'return flow',
  EDIT_STORAGE = 'edit storage',
  UI = 'ui',
  PACKAGING = 'packaging flow',
  SELL_WITH_PICKUP = 'sellWithPickupFlow',
  SELL = 'sellFlow',
  M2_PPA = 'square meter/pay per area',
  BID_FLOW = 'bid flow',
  SERVICE_FROM_STORAGE = 'service from storage',
  STORAGE_UNIT = 'storage unit flow',
}

/**
 * Mapping of slice names and their respective state keys,
 * to assure code consistency and DRYness.
 */
export const stateKeyMappings: Record<SliceNames, string> = {
  [SliceNames.USER]: 'user',
  [SliceNames.SERVICE]: 'servicesFlowNext',
  [SliceNames.STORAGE]: 'storageFlow',
  [SliceNames.RETURN]: 'returnFlow',
  [SliceNames.EDIT_STORAGE]: 'editStorageFlow',
  [SliceNames.UI]: 'ui',
  [SliceNames.PACKAGING]: 'packagingFlow',
  [SliceNames.SELL_WITH_PICKUP]: 'sellWithPickupFlow',
  [SliceNames.SELL]: 'sellFlow',
  [SliceNames.M2_PPA]: 'squareMeterFlow',
  [SliceNames.BID_FLOW]: 'bidFlow',
  [SliceNames.SERVICE_FROM_STORAGE]: 'serviceFromStorage',
  [SliceNames.STORAGE_UNIT]: 'storageUnitFlow',
} as const

/**
 * Redux Toolkit slice keys, as used in store
 */
export type reduxSliceKeys = keyof typeof stateKeyMappings

/**
 * Mapping of slice names and their respective reducer function names,
 * to assure code consistency and DRYness.
 *
 * Naming convention: Be consistent with naming of reducer functions...
 * - ...by form of action (add, remove, update etc.)
 * - ...by type of data they handle as payload (Orderline, ProductCategory etc.)
 */
export const reducerFunctionNames: {
  [K in keyof typeof SliceNames as SliceNames]: Record<string, string>
} = {
  [SliceNames.SERVICE]: {
    applyDiscounts: 'applyDiscounts',
    addServiceOrderLine: 'addServiceOrderLine',
    addStorageOrderLine: 'addStorageOrderLine',
    pruneStorageOrderLines: 'pruneStorageOrderLines',
    removeServiceOrderLine: 'removeServiceOrderLine',
    removeStorageOrderLine: 'removeStorageOrderLine',
    resetAll: 'resetAll',
    setContactPerson: 'setContactPerson',
    setDateAndTime: 'updateDateAndTime',
    setSelectedCategory: 'setSelectedCategory',
    setOrderSuccessPayload: 'setOrderSuccessPayload',
    setPickupAddress: 'setPickupAddress',
    setServiceLevel: 'setServiceLevel',
    setServiceOrderLines: 'setServiceOrderLines',
    setStorageOrderLines: 'setStorageOrderLines',
    setTaasOrderLines: 'setTaasOrderLines',
    setTimeslotOrderlinesService: 'setTimeslotOrderlinesService',
    updateCommentOnServiceOrderLine: 'updateCommentOnServiceOrderLine',
    updateServiceLevelWithPrice: 'updateServiceLevelWithPrice',
    updateDateAndTime: 'updateDateAndTime',
  },
  [SliceNames.SERVICE_FROM_STORAGE]: {
    applyDiscounts: 'applyDiscounts',
    addServiceOrderLine: 'addServiceOrderLine',
    removeServiceOrderLine: 'removeServiceOrderLine',
    removeStorageOrderLine: 'removeStorageOrderLine',
    setSelectedCategory: 'setSelectedCategory',
    setOrderSuccessPayload: 'setOrderSuccessPayload',
    setServiceOrderLines: 'setServiceOrderLines',
    updateCommentOnServiceOrderLine: 'updateCommentOnServiceOrderLine',
  },
  [SliceNames.STORAGE]: {
    setOrderSuccessPayload: 'setOrderSuccessPayload',
    selectDateAndTime: 'selectDateAndTime',
  },
  [SliceNames.RETURN]: {
    setOrderSuccessPayload: 'setOrderSuccessPayload',
  },
  [SliceNames.EDIT_STORAGE]: {},
  [SliceNames.UI]: {
    setCity: 'setCity',
    setCountry: 'setCountry',
    setEstimatedTimeslotCost: 'setEstimatedTimeslotCost',
    setLanguage: 'setLanguage',
    setMainNavFixed: 'setMainNavFixed',
  },
  [SliceNames.PACKAGING]: {
    setOrderSuccessPayload: 'setOrderSuccessPayload',
  },
  [SliceNames.SELL_WITH_PICKUP]: {
    setOrderSuccessPayload: 'setOrderSuccessPayload',
  },
  [SliceNames.SELL]: {
    setOrderSuccessPayload: 'setOrderSuccessPayload',
  },
  [SliceNames.M2_PPA]: {
    setOrderSuccessPayload: 'setOrderSuccessPayload',
  },
  [SliceNames.USER]: {
    fetchUser: 'fetchUser',
  },
  [SliceNames.BID_FLOW]: {},
  [SliceNames.STORAGE_UNIT]: {},
}
